import axios from 'axios'
import Layout from '@/layout.vue'
import PrimeVue from 'primevue/config'
import Aura from '@primevue/themes/aura'
import '@/assets/tailwind.css'
import { VueQueryPlugin } from '@tanstack/vue-query'
import 'primeicons/primeicons.css';

import { createApp, h } from 'vue'
import { createInertiaApp, Link as InertiaLink } from '@inertiajs/vue3'
import { definePreset } from '@primevue/themes'
import ConfirmationService from 'primevue/confirmationservice'
import ToastService from 'primevue/toastservice'

const csrfToken = document.querySelector('meta[name=csrf-token]').content
axios.defaults.headers.common['X-CSRF-Token'] = csrfToken

const Noir = definePreset(Aura, {
  semantic: {
    primary: {
      50: '{gray.50}',
      100: '{gray.100}',
      200: '{gray.200}',
      300: '{gray.300}',
      400: '{gray.400}',
      500: '{gray.500}',
      600: '{gray.600}',
      700: '{gray.700}',
      800: '{gray.800}',
      900: '{gray.900}',
      950: '{gray.950}',
    },
    colorScheme: {
      light: {
        primary: {
          color: '{gray.950}',
          inverseColor: '#ffffff',
          hoverColor: '{gray.900}',
          activeColor: '{gray.800}',
        },
        highlight: {
          background: '{gray.950}',
          focusBackground: '{gray.700}',
          color: '#ffffff',
          focusColor: '#ffffff',
        },
      },
      dark: {
        primary: {
          color: '{gray.50}',
          inverseColor: '{gray.950}',
          hoverColor: '{gray.100}',
          activeColor: '{gray.200}',
        },
        highlight: {
          background: 'rgba(250, 250, 250, .16)',
          focusBackground: 'rgba(250, 250, 250, .24)',
          color: 'rgba(255,255,255,.87)',
          focusColor: 'rgba(255,255,255,.87)',
        },
      },
    },
  },
})

createInertiaApp({
  resolve: (name) => {
    const pages = import.meta.glob('../pages/**/*.page.vue')
    const importPage = pages[`../pages/${name}.page.vue`]
    return importPage().then((module) => {
      module.default.layout = module.default.layout || Layout
      return module.default
    })
  },
  setup({ el, App, props, plugin }) {
    createApp({ render: () => h(App, props) })
      .use(PrimeVue, {
        theme: {
          preset: Noir,
          options: {
            darkModeSelector: '.dark-mode-selector',
            cssLayer: {
              name: 'primevue',
              order: 'tailwind-base, primevue, tailwind-utilities',
            },
          },
        },
        ripple: true,
      })
      .use(ConfirmationService)
      .use(ToastService)
      .use(VueQueryPlugin)
      .use(plugin)
      .component('InertiaLink', InertiaLink)
      .mount(el)
  },
}).then(() => {})
